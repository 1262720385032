import { NavLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useState, useEffect } from "react";

const FeedbackList = (props) => {
    
    const { user, isAuthenticated, loginWithRedirect } = useAuth0();
    const dataFeed = props.dataFeed
    const [upvotesFeed, setUpvotes] = useState([])

    const fetchUpvotes = () => {
        fetch('https://feedback-hub-backend.vercel.app/public/upvotes')
        .then(res => res.json())
        .then((actualData) => setUpvotes(actualData))
        .catch((err) => err)
    }

    useEffect(() => {
        fetchUpvotes()
    }, [])

    const getUpvoteById = (feedbackId) => {
        const upvotesOfThisFeedback = upvotesFeed.filter(item => item.feedback_id === feedbackId)
        return upvotesOfThisFeedback.length
    }

    const getMyUpvoteById = (feedbackId) => {
        const myUpvoteOfThisFeedback = upvotesFeed.some(item => item.feedback_id === feedbackId && item.user_id === user.sub)
        return myUpvoteOfThisFeedback
    }
    
    const handleUpvote = (e) => {
        const newUpvote = {
            feedbackId: e.currentTarget.id,
            userNickname: user.nickname,
            userEmail: user.email,
            userPicture: user.picture,
            userId: user.sub,
            }
        fetch(`https://feedback-hub-backend.vercel.app/public/upvotes`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                },
            body: JSON.stringify(newUpvote)
        })
        .then(fetchUpvotes)
        .catch((err) => err)
    }

    const handleDownvote = (e) => {
        const feedbackId = e.currentTarget.id
        const upvoteUser = {
            userId: user.sub
        }
        fetch(`https://feedback-hub-backend.vercel.app/public/upvotes/${feedbackId}`, {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                },
            body: JSON.stringify(upvoteUser)
        })
        .then(fetchUpvotes)
        .catch((err) => err)
    }

    return (
        <div className="flex-1 h-full space-y-4">
            {dataFeed.map(item => {
                return (
                    <div key={item.id} className="flex bg-[#25262C] rounded-lg py-6 px-5 space-x-5 hover:bg-gray-700">
                        <button id={item.id} className="h-12 w-12 shrink-0 border-gray-200 border rounded-md flex flex-col justify-center items-center" onClick={e => isAuthenticated? (getMyUpvoteById(item.id)? handleDownvote(e): handleUpvote(e)) : loginWithRedirect() }>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={isAuthenticated? (getMyUpvoteById(item.id)? "#1f6feb": "currentColor"): "currentColor"} viewBox="0 0 16 16">
                                <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                            </svg>
                            <p>{getUpvoteById(item.id)}</p>
                        </button>
                        <NavLink to= {`/feedback/${item.id}`} className="flex-1">
                            <h3 className="font-bold">{item.title}</h3>
                            <p className="text-[0.65rem] tracking-widest mt-2 bg-green-600 w-fit px-1.5 rounded uppercase">{item.category}</p>
                            <p className="text-sm mt-5 pb-7 border-b border-gray-600">{item.description}</p>
                            <p className="text-sm mt-3">Created by {item.user_nickname} at {item.created_on}</p>
                        </NavLink>
                    </div>
                )
            })}
        </div>
    )

}

export default FeedbackList;