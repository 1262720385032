import Home from "./components/public/Home";
import { Routes, Route } from 'react-router-dom'
import Admin from "./components/admin/Admin";

const App = () => {
  return (
    <div className="bg-[#1B1C22] text-gray-200 flex-col h-screen overflow-y-scroll">
        <Routes>
            <Route exact path="/admin/*" element={<Admin />} />
            <Route path="/*" element={<Home />} />
        </Routes>
    </div>
  );
}

export default App;
