import Nav from "../Nav";
import HomeFeed from "./HomeFeed";
import FeedbackDetails from "./FeedbackDetails"
import { Routes, Route } from "react-router-dom";

const Home = () => {
    return (
        <div>
            <Nav />
            <Routes>
                <Route exact path="/" element={<HomeFeed />} />
                <Route exact path="/feedback/:id" element={<FeedbackDetails />} />
            </Routes>
            </div>
    )
}

export default Home;