import { useParams } from "react-router-dom"
import { useState, useEffect } from "react"
import { useAuth0 } from "@auth0/auth0-react";

const FeedbackDetails = () => {
    const feedbackId = useParams().id
    const { user } = useAuth0();

    const { getAccessTokenSilently } = useAuth0();
    // const getToken = async () => {
    //     try {
    //         const token = await getAccessTokenSilently()
    //         return token
    //     } catch (err) {console.log(err)}
    // }
    // const accessToken = await getAccessTokenSilently()
    // console.log(accessToken)

    // const auth0Token = "Bearer " + accessToken
    // console.log(auth0Token)

    const [currentFeedback, setCurrentFeedback] = useState({})
    const [comments, setComments] = useState([])

    const fetchComment = () => {
            fetch(`https://feedback-hub-backend.vercel.app/public/comments/${feedbackId}`)
            .then(res => res.json())
            .then((actualData) => setComments(actualData))
            .catch((err) => err)
        }
    const fetchFeedback = () => {
        fetch(`https://feedback-hub-backend.vercel.app/public/feedback/${feedbackId}`)
        .then(res => res.json())
        .then((actualData) => setCurrentFeedback(actualData[0]))
        .catch((err) => err)
    }
    useEffect(() => {
        fetchFeedback()
        fetchComment()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [feedbackId])
    
    const [newComment, setNewComment] = useState({
        comment: "",
        feedbackId: "",
        userNickname: "",
        userEmail: "",
        userPicture: "",
        userId: "",
    })

    const handleChange = async (e) => {
        const newFeedback = {...currentFeedback}

        newFeedback[e.target.name] = e.target.value

        const accessToken = await getAccessTokenSilently()
        
        fetch(`https://feedback-hub-backend.vercel.app/private/feedback/${feedbackId}`, {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer " + accessToken,
                },
            body: JSON.stringify(newFeedback)
        })
        .then(fetchFeedback)
        .catch((err) => err)
    }

    const handleChangeComment = (e) => {
        const tempNewComment = {...newComment, comment: e.target.value}
        setNewComment(tempNewComment)
    }

    const handleSubmitUser = (e) => {
        e.preventDefault();
        const tempNewComment = {
            ...newComment,
            feedbackId: feedbackId,
            userNickname: user.nickname,
            userEmail: user.email,
            userPicture: user.picture,
            userId: user.sub,
            }
        setNewComment(tempNewComment)
        console.log(newComment)
        e.target.reset();
        fetch(`https://feedback-hub-backend.vercel.app/public/comments`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                },
            body: JSON.stringify(tempNewComment)
        })
        .then(fetchComment)
        .catch((err) => err)
    }

    return (
        <div className="flex-col px-4 py-4 lg:px-20 lg:py-10 overflow-y-scroll flex-1 max-h-screen z-10 bg-[#1B1C22]">
            <div className="text-2xl font-bold">{currentFeedback.title}</div>
            <p className="text-sm mt-3 text-gray-400">Created by {currentFeedback.user_nickname} at {currentFeedback.created_on}</p>
            <div className="mt-8">{currentFeedback.description}</div>
            <div className="flex-col border-b border-gray-500 pb-6">
                <div className="mt-4 flex items-center">
                    <label htmlFor="assignee_nickname" className="mr-2 w-36 text-gray-400">Assigned to</label>
                    <div className="mt-1">
                        <select
                            id="assignee_nickname"
                            name="assignee_nickname"
                            required
                            className="py-1 px-2 text-gray-200 rounded-lg w-40 bg-gray-800 hover:bg-gray-700"
                            value={currentFeedback.assignee_nickname}
                            onChange={e => handleChange(e)}
                        >
                            <option value="unassigned">Unassigned</option>
                            <option value="joen">Joen</option>
                            <option value="john">John</option>
                            <option value="anna">Anna</option>
                        </select>
                    </div>
                </div>
                <div className="mt-4 flex items-center">
                    <label htmlFor="feedback_status" className="mr-2 w-36 text-gray-400">Status</label>
                    <div className="mt-1">
                        <select
                            id="feedback_status"
                            name="feedback_status"
                            required
                            className="py-1 px-2 text-gray-200 rounded-lg w-40 bg-gray-800 hover:bg-gray-700"
                            value={currentFeedback.feedback_status}
                            onChange={e => handleChange(e)}
                        >
                            <option value="review">Under Review</option>
                            <option value="new">New</option>
                            <option value="doing">Doing</option>
                            <option value="done">Done</option>
                        </select>
                    </div>
                </div>
                <div className="mt-4 flex items-center">
                    <label htmlFor="category" className="mr-2 w-36 text-gray-400">Category</label>
                    <div className="mt-1">
                        <select
                            id="category"
                            name="category"
                            required
                            className="py-1 px-2 text-gray-200 rounded-lg w-40 bg-gray-800 hover:bg-gray-700"
                            value={currentFeedback.category}
                            onChange={e => handleChange(e)}
                        >
                            <option value="bugs">Bug</option>
                            <option value="features">Features</option>
                        </select>
                    </div>
                </div>
            </div>
            <div>
                <div className="mt-6">Acitivities</div>
                <ul className="divide-y divide-gray-600">
                    {comments.map((item) => (
                            <li key={item.id} className="py-4 flex space-x-3">
                            <img className="h-6 w-6 rounded-full" src={item.user_picture} alt="" />
                            <div className="flex-1">
                                <div className="flex space-x-1 text-sm flex-wrap">
                                    <p className="font-bold">{item.user_nickname}</p>
                                    <p className="font-medium text-gray-400"> commented on</p>
                                    <p className="font-medium text-gray-400">{item.commented_on}:</p>
                                </div>
                                <p className="mt-2">{item.comment}</p>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            <form onSubmit={e => handleSubmitUser(e)} className="mt-4">
                <label htmlFor="message" className="block">Add a new comment:</label>
                <div className="mt-1">
                    <textarea
                        id="message"
                        name="message"
                        required
                        rows={4}
                        className="py-3 px-4 block w-full text-gray-900 border border-gray-300 rounded-lg"
                        onChange={e => handleChangeComment(e)}
                        />
                </div>
                <div className="mt-4 flex justify-end">
                    <button
                        type="submit"
                        className=" px-6 py-2 border border-transparent rounded-lg shadow-sm text-white text-bold bg-[#1f6feb] hover:bg-[#185ec9]"
                        >
                        Comment
                    </button>
                </div>
            </form>
        </div>
    )
}

export default FeedbackDetails