import { NavLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";


const Nav = () => {
    const { loginWithRedirect, logout, isAuthenticated } = useAuth0();

    return(
        <div className="bg-[#1B1C22] py-5 border-b border-gray-600 sticky top-0 w-full">
            <div className="-mt-2 px-4 flex items-center justify-between flex-wrap sm:flex-nowrap lg:w-3/4 mx-auto ml-auto mr-auto">
                <div className="mt-2">
                    <NavLink to="/" className="text-lg leading-6 font-bold text-gray-200">Feedback Hub</NavLink>
                </div>
                {isAuthenticated ? 
                    <div className="flex">
                        <NavLink to="/admin/all" className="ml-4 mt-2 flex-shrink-0">
                            <button
                                type="button"
                                className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                            Dashboard
                            </button>
                        </NavLink>
                        <div className="ml-4 mt-2 flex-shrink-0">
                        <button
                            type="button"
                            className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={() => logout()}
                            >
                        Log out
                        </button>
                        </div>
                    </div>
                    :
                <div className="ml-4 mt-2 flex-shrink-0">
                    <button
                        type="button"
                        className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={() => loginWithRedirect({ appState: { returnTo: window.location.pathname }})}
                        >
                    Log in
                    </button>
                </div>
                    }
            </div>
        </div>
    )
}

export default Nav;