import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

const FeedbackDetails = () => {
    const { user, isAuthenticated, loginWithRedirect } = useAuth0();
    const feedbackId = useParams().id

    const [currentFeedback, setCurrentFeedback] = useState([])
    const [newComment, setNewComment] = useState({
        comment: "",
        feedbackId: feedbackId,
        userNickname: "",
        userEmail: "",
        userPicture: "",
        userId: "",
    })

    const [comments, setComments] = useState([])

    const [upvotes, setUpvotes] = useState([])

    const fetchUpvote = () => {
        fetch(`https://feedback-hub-backend.vercel.app/public/upvotes/${feedbackId}`)
        .then(res => res.json())
        .then(data => setUpvotes(data))
        .catch(err => console.log(err))
    }

    const fetchFeedback = () => {
        fetch(`https://feedback-hub-backend.vercel.app/public/feedback/${feedbackId}`)
        .then(res => res.json())
        .then((actualData) => setCurrentFeedback(actualData))
        .catch((err) => err)
    }

    const fetchComment = () => {
        fetch(`https://feedback-hub-backend.vercel.app/public/comments/${feedbackId}`)
        .then(res => res.json())
        .then((actualData) => setComments(actualData))
        .catch((err) => err)
    }
    useEffect(() => {
        fetchFeedback()
        fetchUpvote()
        fetchComment()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getMyUpvoteById = (feedbackId) => {
        const myUpvoteOfThisFeedback = upvotes.some(item => item.user_id === user.sub)
        return myUpvoteOfThisFeedback
    }

    const handleUpvote = (e) => {
        e.preventDefault();
        const newUpvote = {
            feedbackId: feedbackId,
            userNickname: user.nickname,
            userEmail: user.email,
            userPicture: user.picture,
            userId: user.sub,
            }
        fetch(`https://feedback-hub-backend.vercel.app/public/upvotes`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                },
            body: JSON.stringify(newUpvote)
        })
        .then(fetchUpvote)
        .catch((err) => err)
    }

    const handleDownvote = (e) => {
        e.preventDefault();
        const upvoteUser = {
            userId: user.sub
        }
        fetch(`https://feedback-hub-backend.vercel.app/public/upvotes/${feedbackId}`, {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                },
            body: JSON.stringify(upvoteUser)
        })
        .then(fetchUpvote)
        .catch((err) => err)
    }

    const handleChange = (e) => {
        const tempNewComment = {...newComment, comment: e.target.value}
        setNewComment(tempNewComment)
        console.log(newComment)
    }

    const handleSubmitUser = (e) => {
        e.preventDefault();
        e.target.reset();
        const tempNewComment = {
            ...newComment,
            userNickname: user.nickname,
            userEmail: user.email,
            userPicture: user.picture,
            userId: user.sub,
            }
        setNewComment(tempNewComment)
        console.log(newComment)
        fetch(`https://feedback-hub-backend.vercel.app/public/comments`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                },
            body: JSON.stringify(tempNewComment)
        })
        .then(fetchComment)
        .catch((err) => err)
    }

    return (
        <div className="lg:w-3/4 mx-auto flex px-4 space-x-4 py-4">
            <div className="flex-col flex-1">
                <div className="flex bg-[#25262C] rounded-lg py-6 px-5 space-x-5">
                    <button className="h-12 w-12 shrink-0 border-gray-200 border rounded-md flex flex-col justify-center items-center" onClick={e => isAuthenticated? (getMyUpvoteById(feedbackId)? handleDownvote(e): handleUpvote(e)) : loginWithRedirect({ appState: { returnTo: window.location.pathname }}) }>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={isAuthenticated? (getMyUpvoteById(feedbackId)? "#1f6feb": "currentColor"): "currentColor"} viewBox="0 0 16 16">
                            <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                        </svg>
                        <p>{upvotes.length}</p>
                    </button>
                    {currentFeedback.map(feedbackItem => {
                        return (
                            <div key={feedbackItem.id} className="flex-1">
                                <h3 className="font-bold">{feedbackItem.title}</h3>
                                <p className="text-[0.65rem] tracking-widest mt-2 bg-green-600 w-fit px-1.5 rounded uppercase">{feedbackItem.category}</p>
                                <p className="text-sm mt-5 pb-7 border-b border-gray-600">{feedbackItem.description}</p>
                                <p className="text-sm mt-3">Created by {feedbackItem.user_nickname} on {feedbackItem.created_on}</p>
                            </div>
                        )
                    })}
                </div>
                <div>
                    <div className="mt-8 font-bold">Acitivities</div>
                    <ul className="divide-y divide-gray-600">
                        {comments.map((item) => (
                            <li key={item.id} className="py-4 flex space-x-3">
                                <img className="h-6 w-6 rounded-full" src={item.user_picture} alt="" />
                                <div className="flex-1">
                                    <div className="flex space-x-1 text-sm flex-wrap">
                                        <p className="font-bold">{item.user_nickname}</p>
                                        <p className="font-medium text-gray-400"> commented on</p>
                                        <p className="font-medium text-gray-400">{item.commented_on}:</p>
                                    </div>
                                    <p className="mt-2">{item.comment}</p>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <form onSubmit={e => isAuthenticated? handleSubmitUser(e): loginWithRedirect({ appState: { returnTo: window.location.pathname }})} className="mt-4">
                    <label htmlFor="message" className="block">Add a new comment:</label>
                    <div className="mt-1">
                        <textarea
                            id="message"
                            name="message"
                            required
                            rows={4}
                            className="py-3 px-4 block w-full text-gray-900 border border-gray-300 rounded-lg"
                            disabled={!isAuthenticated}
                            onChange={e => handleChange(e)}
                            />
                    </div>
                    <div className="mt-4 flex justify-end">
                        <button
                            type="submit"
                            className=" px-6 py-2 border border-transparent rounded-lg shadow-sm text-white text-bold bg-[#1f6feb] hover:bg-[#185ec9]"
                            >
                            {isAuthenticated? "Comment" : "Login to comment"}
                        </button>
                    </div>
                </form>
            </div>
            <div className="w-80 shrink-0 hidden lg:block sticky top-24 h-full rounded-lg bg-gray-700 p-4 px-6 py-6 space-y-6">
                <div className="font-bold">Voters:</div>
                <ul>
                    {upvotes.map((item) => (
                    <li key={item.id} className="py-2">
                        <div className="flex space-x-3">
                            <img className="h-6 w-6 rounded-full" src={item.user_picture} alt="" />
                            <h3 className="text-sm font-medium">{item.user_nickname}</h3>
                        </div>
                    </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default FeedbackDetails;