import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";

const AddNew = (props) => {
	const { loginWithRedirect, isAuthenticated, user } = useAuth0();

	const [newFeedback, setNewFeedback] = useState({
		title: "",
		category: "bugs",
		description: "",
		userNickname: "",
		userEmail: "",
		userPicture: "",
		userId: "",
	});

	const handleChange = (e) => {
		const tempNewFeedback = { ...newFeedback };
		tempNewFeedback[e.target.name] = e.target.value;
		setNewFeedback(tempNewFeedback);
		console.log(newFeedback);
	};

	const handleSubmitUser = (e) => {
		e.preventDefault();
		e.target.reset();
		const tempNewFeedback = {
			...newFeedback,
			userNickname: user.nickname,
			userEmail: user.email,
			userPicture: user.picture,
			userId: user.sub,
		};
		setNewFeedback(tempNewFeedback);
		fetch(`https://feedback-hub-backend.vercel.app/public/feedback`, {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json;charset=UTF-8",
			},
			body: JSON.stringify(tempNewFeedback),
		})
			.then(props.fetchFeedback)
			.catch((err) => err);
	};

	const [showCreate, setShowCreate] = useState(false);

	const toggleShowCreate = () => {
		setShowCreate(!showCreate);
	};

	return (
		<>
			<div
				className={`${
					showCreate ? "block sticky bottom-[4rem] w-full" : "hidden"
				} lg:w-80 shrink-0 lg:h-full relative lg:sticky lg:top-24 lg:block`}
			>
				<form
					onSubmit={(e) =>
						isAuthenticated ? handleSubmitUser(e) : loginWithRedirect()
					}
					className="flex-col px-6 py-6 space-y-6 bg-gray-700 rounded-lg"
				>
					<div className="font-bold">Submit a new feedback</div>
					<div>
						<label htmlFor="title" className="block text-sm">
							Title
						</label>
						<div className="mt-1">
							<input
								id="title"
								name="title"
								type="text"
								required
								className="py-3 px-4 block w-full text-gray-900 border-gray-300 rounded-lg"
								disabled={!isAuthenticated}
								onChange={(e) => handleChange(e)}
							/>
						</div>
					</div>
					<div>
						<label htmlFor="category" className="block text-sm">
							Category
						</label>
						<div className="mt-1">
							<select
								id="category"
								name="category"
								required
								defaultValue="bugs"
								className="py-3 px-4 block w-full text-gray-900 border-gray-300 rounded-lg"
								disabled={!isAuthenticated}
								onChange={(e) => handleChange(e)}
							>
								<option value="bugs">Bugs</option>
								<option value="features">Features</option>
							</select>
						</div>
					</div>
					<div>
						<label htmlFor="description" className="block text-sm">
							Description
						</label>
						<div className="mt-1">
							<textarea
								id="description"
								name="description"
								required
								rows={4}
								className="py-3 px-4 block w-full text-gray-900 border border-gray-300 rounded-lg"
								disabled={!isAuthenticated}
								onChange={(e) => handleChange(e)}
							/>
						</div>
					</div>
					<div>
						<button
							type="submit"
							className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-lg font-bold tracking-wide shadow-sm text-white text-bold bg-indigo-600 hover:bg-indigo-700"
						>
							{isAuthenticated ? "Submit" : "Login to submit"}
						</button>
					</div>
				</form>
			</div>
			<button
				onClick={toggleShowCreate}
				className="lg:hidden absolute bottom-4 right-4 bg-indigo-600 rounded-lg p-3 drop-shadow-lg"
			>
				{!showCreate ? (
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="16"
						height="16"
						fill="currentColor"
						viewBox="0 0 16 16"
					>
						<path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
						<path
							fillRule="evenodd"
							d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
						/>
					</svg>
				) : (
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="16"
						height="16"
						fill="currentColor"
						viewBox="0 0 16 16"
					>
						<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
					</svg>
				)}
			</button>
		</>
	);
};

export default AddNew;
