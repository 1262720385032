import { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import FeedbackDetails from './FeedbackDetails';
import DoneFeedbackList from './DoneFeedbackList'
import { withAuthenticationRequired } from "@auth0/auth0-react";

const DoneFeedback = () => {

    const [emptyState, setEmptyState] = useState(false);
    const [dataFeed, setDataFeed] = useState([])

    const fetchFeedback = () => {
        fetch('https://feedback-hub-backend.vercel.app/public/feedbackfilter/done')
        .then(res => res.json())
        .then((actualData) => setDataFeed(actualData))
        .catch((err) => err)
    }

    useEffect(() => {
        fetchFeedback();
    }, [])

    const EmptyFeedback = () => {
        useEffect(() => {
            setEmptyState(true);
          }, [])
        return (
            <div className='w-full flex-col justify-center items-center flex-1 relative overflow-y-auto box-border min-h-screen'>
                <div className="bg-empty-pattern bg-top h-1/2 w-full"></div>
                <div className='text-gray-400 space-y-8 mx-12'>
                <h2 className="text-center text-3xl font-extrabold tracking-tight sm:text-4xl">Done</h2>
                <p className='mx-24 text-center'>Select one to check on it.</p>
                </div>
            </div>
        )
    }
    
    const emptyClassName = "z-10 absolute overflow-y-auto lg:relative flex flex-col w-full lg:max-w-md shrink-0 border-r border-neutral-800 pb-10 transition duration-200 ease-in-out max-h-screen min-h-screen transform translate-x-0 text-neutral-300"
    const notEmptyClassName = "absolute overflow-y-auto lg:relative flex flex-col w-full shrink-0 border-r border-neutral-800 pb-10 transition duration-200 ease-in-out -translate-x-full max-h-screen min-h-screen transform lg:translate-x-0 text-neutral-300"
    
    return (
        <div className="flex flex-1">
            <div className={emptyState? emptyClassName : notEmptyClassName}>
                <DoneFeedbackList dataFeed={dataFeed}/>
            </div>
            <Routes>
                <Route exact path="/" element={<EmptyFeedback />} />    
                <Route path="/feedback/:id" element= {<FeedbackDetails />}/>
            </Routes>
        </div>
    )
}

export default withAuthenticationRequired(DoneFeedback);