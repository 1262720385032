import { useEffect, useState } from 'react';
import {NavLink} from 'react-router-dom'


const activeClassName = "z-10 flex mx-3 my-0.5 p-4 text-sm rounded-lg border-none py-3 bg-gray-700 text-white";
const inactiveClassName = "z-10 flex mx-3 my-0.5 p-4 text-sm rounded-lg border-none py-3 hover:bg-zinc-800 hover:text-white"

const ReviewFeedbackList = (props) => {

    const feedbackData = props.dataFeed

    const [upvotesFeed, setUpvotes] = useState([])
    const [commentsFeed, setComments] = useState([])

    const fetchUpvotes = () => {
        fetch('https://feedback-hub-backend.vercel.app/public/upvotes')
        .then(res => res.json())
        .then((actualData) => setUpvotes(actualData))
        .catch((err) => err)
    }
    const fetchComments = () => {
        fetch('https://feedback-hub-backend.vercel.app/public/comments')
        .then(res => res.json())
        .then((actualData) => setComments(actualData))
        .catch((err) => err)
    }

    useEffect(() => {
        fetchUpvotes()
        fetchComments()
    }, [])

    const getUpvoteById = (feedbackId) => {
        const upvotesOfThisFeedback = upvotesFeed.filter(item => item.feedback_id === feedbackId)
        return upvotesOfThisFeedback.length
    }

    const getCommentById = (feedbackId) => {
        const commentsOfThisFeedback = commentsFeed.filter(item => item.feedback_id === feedbackId)
        return commentsOfThisFeedback.length
    }

    return (
        <div className='flex-col lg:max-w-md overflow-y-auto max-h-screen py-4 space-y-2 border-r border-neutral-800 bg-[#1B1C22]'>
            {feedbackData.map(item => (
                <NavLink to= {`/admin/review/feedback/${item.id}`} key={item.created_on} className={({ isActive }) => isActive ? activeClassName : inactiveClassName}>
                    <div className="flex flex-col justify-center">
                        <div className="font-semibold">{item.title}</div>
                        <div className="text-gray-100 text-opacity-60 text-sm mt-1">{item.description}</div>
                        <div className='flex mt-2 space-x-2 text-gray-100 text-opacity-80'>
                            <div>{getUpvoteById(item.id)} upvotes</div>
                            <div>{getCommentById(item.id)} comments</div>
                        </div>
                    </div>
                </NavLink>
            ))}
            {/* <div className='sticky bottom-0 text-center px-6 py-4 bg-zinc-900'>
                <button className='py-2 w-full rounded-lg bg-blue-700'>Create new</button>
            </div> */}
        </div>
    )
}

export default ReviewFeedbackList;