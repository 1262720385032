import {NavLink} from 'react-router-dom'
import { useAuth0 } from "@auth0/auth0-react";

const Nav = (props) => {

    const { logout } = useAuth0();
    const handleClick = () => {
        props.togglePhoneNav()
    }

    let activeClassName = "flex flex-1 mx-3 my-0.5 space-x-2 px-4 py-2 text-sm rounded-lg border-none bg-[#1f6feb] hover:bg-zinc-800 hover:text-white text-white"

    let inactiveClassName = "flex flex-1 mx-3 my-0.5 space-x-2 px-4 py-2 text-sm rounded-lg border-none hover:bg-zinc-800 hover:text-white"

    return (
        <>
        {/* <div className= "z-50 absolute lg:relative translate-x-0 transition-all ease-in duration-200 border-r border-neutral-800"> */}
        <div className={props.showNav? "z-50 absolute lg:relative translate-x-0 transition-all ease-in duration-200 border-r border-neutral-800": "z-50 absolute lg:relative -translate-x-full lg:translate-x-0 transition-all ease-in duration-200 border-r border-gray-800"}>
            <nav className="overflow-y-scroll flex h-full min-h-screen max-h-screen flex-col w-52 shrink-0 bg-[#1B1C22]">
                <div className="flex items-center space-x-2 px-4 py-3 mb-1.5 text-sm border-none">
                    <button type="button" className="text-neutral-300 lg:hidden hover:text-white" onClick={handleClick}>
                        <span className="sr-only">Navigation</span>
                        <svg className="h-8 w-8 hover:bg-neutral-700 rounded-lg" viewBox="-9 -11 29 30" fill="currentColor">
                            <path d="M0 0L10 10M10 0L0 10" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"></path>
                        </svg>
                    </button>    
                    <span className="font-extrabold text-neutral-300 text-sm">Dashboard</span>
                </div>
                <div className="mb-2">
                    <ul onClick={handleClick} >
                        <li className="flex text-neutral-300">
                            <NavLink to ="/" className={({ isActive }) => isActive ? activeClassName : inactiveClassName}>
                                <div className="mr-2">
                                    <svg className="h-5 w-5" viewBox="-4 -2 24 24" fill="currentColor">
                                        <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" strokeWidth="0" strokeLinecap="round"></path>
                                    </svg>
                                </div>
                                <span className="font-semibold">Public Portal</span>
                            </NavLink>                        
                        </li>
                    </ul>
                </div>
                <div className="mb-4"> {/*What I do*/}
                    <h4 className="mx-3 my-0.5 px-4 pt-4 pb-2 text-xs text-neutral-400">Feedback</h4>
                    <ul onClick={handleClick} >
                        <li className="flex text-neutral-300">
                            <NavLink to ="/admin/all" className={({ isActive }) => isActive ? activeClassName : inactiveClassName}>
                                <div className="mr-2">
                                <svg className="h-5 w-5" viewBox="-4 -4 23 23" fill="currentColor">
                                        <path d="M12.643 15C13.979 15 15 13.845 15 12.5V5H1v7.5C1 13.845 2.021 15 3.357 15h9.286zM5.5 7h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1zM.8 1a.8.8 0 0 0-.8.8V3a.8.8 0 0 0 .8.8h14.4A.8.8 0 0 0 16 3V1.8a.8.8 0 0 0-.8-.8H.8z"/>
                                    </svg>
                                </div>
                                <span className="font-semibold">All Feedback</span>
                            </NavLink>                        
                        </li>
                        <li className="flex text-neutral-300">
                            <NavLink to ="/admin/review" className={({ isActive }) => isActive ? activeClassName : inactiveClassName}>
                                <div className="mr-2">
                                    <svg className="h-5 w-5" viewBox="-4 -4 23 23" fill="currentColor">
                                        <path d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V9H2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM2 12h12v2a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-2zM.5 10a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1H.5z"/>
                                    </svg>
                                </div>
                                <span className="font-semibold">Under Review</span>
                            </NavLink>                        
                        </li>
                        <li className="flex text-neutral-300">
                            <NavLink to ="/admin/new" className={({ isActive }) => isActive ? activeClassName : inactiveClassName}>
                                <div className="mr-2">
                                    <svg className="h-5 w-5" viewBox="-4 -4 23 23" fill="currentColor">
                                        <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM8.5 7v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 1 0z"/>
                                    </svg>
                                </div>
                                <span className="font-semibold">New</span>
                            </NavLink>                        
                        </li>
                        <li className="flex text-neutral-300">
                            <NavLink to ="/admin/doing" className={({ isActive }) => isActive ? activeClassName : inactiveClassName}>
                                <div className="mr-2">
                                    <svg className="h-5 w-5" viewBox="-4 -4 23 23" fill="currentColor">
                                        <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zm-3 2v.634l.549-.317a.5.5 0 1 1 .5.866L7 7l.549.317a.5.5 0 1 1-.5.866L6.5 7.866V8.5a.5.5 0 0 1-1 0v-.634l-.549.317a.5.5 0 1 1-.5-.866L5 7l-.549-.317a.5.5 0 0 1 .5-.866l.549.317V5.5a.5.5 0 1 1 1 0zm-2 4.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1zm0 2h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1z"/>
                                    </svg>
                                </div>
                                <span className="font-semibold">Doing</span>
                            </NavLink>                        
                        </li>
                        <li className="flex text-neutral-300">
                            <NavLink to ="/admin/done" className={({ isActive }) => isActive ? activeClassName : inactiveClassName}>
                                <div className="mr-2">
                                    <svg className="h-5 w-5" viewBox="-4 -4 23 23" fill="currentColor">
                                        <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zm1.354 4.354-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708.708z"/>
                                    </svg>
                                </div>
                                <span className="font-semibold">Done</span>
                            </NavLink>                        
                        </li>
                    </ul>
                </div>
                <div className="mb-8 mt-auto"> 
                    <h4 className="mx-3 my-0.5 px-4 pt-4 pb-2 text-xs text-neutral-400">Profile</h4>
                    <ul onClick={logout} className="cursor-pointer">
                        <li className="flex text-neutral-300">
                            <div className="flex flex-1 mx-3 my-0.5 py-2 px-4 text-sm rounded-lg border-none bg-black hover:bg-zinc-800" >
                                <div className="flex flex-1 items-center space-x-2">
                                <div className="mr-2">
                                    <svg className="h-5 w-5" viewBox="-4 -4 23 23" fill="currentColor">
                                        <path d="M4 16s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H4Zm4-5.95a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"/>
                                        <path d="M2 1a2 2 0 0 0-2 2v9.5A1.5 1.5 0 0 0 1.5 14h.653a5.373 5.373 0 0 1 1.066-2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v9h-2.219c.554.654.89 1.373 1.066 2h.653a1.5 1.5 0 0 0 1.5-1.5V3a2 2 0 0 0-2-2H2Z"/>
                                    </svg>
                                </div>
                                <span className="font-semibold text-sm">Logout</span>
                                </div>
                                <div className="ml-auto">
                                    <svg className="h-5 w-5" viewBox="-4 -4 23 23" fill="currentColor">
                                        <path fillRule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"/>
                                        <path fillRule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/>
                                    </svg>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>       
        </div> 
        </>
    )
}

export default Nav;
