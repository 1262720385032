import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Nav from './Nav';
import PhoneNav from './PhoneNav';
import { withAuthenticationRequired } from "@auth0/auth0-react";
import ReviewFeedback from './ReviewFeedback'
import NewFeedback from './NewFeedback';
import AllFeedback from './AllFeedback'
import DoingFeedback from './DoingFeedback'
import DoneFeedback from './DoneFeedback';

const Admin = () => {

    const [showNav, setShowNav] = useState(false)

    const toggleNav = () => {
        setShowNav(prevShowNav => !prevShowNav)
        console.log(showNav)
    }

    return (
        <div className="flex w-screen">
            <Nav showNav = {showNav} togglePhoneNav = {toggleNav} />
            <div className="flex flex-col w-full">
            <PhoneNav togglePhoneNav = {toggleNav}/>
            <Routes>
                <Route exact path="/all/*" element={<AllFeedback/>} />    
                <Route path="/review/*" element= {<ReviewFeedback />}/>
                <Route path="/new/*" element= {<NewFeedback />}/>
                <Route path="/doing/*" element= {<DoingFeedback />}/>
                <Route path="/done/*" element= {<DoneFeedback />}/>
            </Routes>
            </div>
        </div>
    )
}

export default withAuthenticationRequired(Admin);