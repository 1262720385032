import AddNew from "./AddNew";
import FeedbackList from "./FeedbackList";
import { useEffect, useState } from "react";

const HomeFeed = () => {

    const [dataFeed, setDataFeed] = useState([])

    const fetchFeedback = () => {
        fetch('https://feedback-hub-backend.vercel.app/public/feedback')
        .then(res => res.json())
        .then((actualData) => setDataFeed(actualData))
        .catch((err) => err)
    }

    useEffect(() => {
        fetchFeedback();
    }, [])

    return (
        <div>
            <div className="lg:w-3/4 w-screen flex-wrap mx-auto flex px-4 lg:space-x-4 py-4">
                <FeedbackList 
                    dataFeed = {dataFeed}
                    setDataFeed = {setDataFeed}
                />
                <AddNew fetchFeedback = {fetchFeedback} />
            </div>
        </div>
    )
}

export default HomeFeed;